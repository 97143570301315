import React from "react"
import loadable from "@loadable/component"
import { graphql } from "gatsby"

const Layout = loadable(() => import("@common/Layout/Layout"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))
const HealthCare = loadable(() => import("@components/HealthCare"))

const HealthCarePage = ({ path, data }) => {
  return (
    <>
      <SEO
        title="Software development for healthcare companies"
        titleTemplate="Impressit"
        SEOData={data.allStrapiSeoDescriptions?.nodes}
        description="Whether you are a medtech startup, clinic, doctor, wellness business, or any other healthcare company, our expert developers can create customized apps and digital solutions to meet your specific needs."
        path={path}
      />
      <Layout path={path} isNoPadding>
        <HealthCare />
      </Layout>
    </>
  )
}
export default HealthCarePage

export const healthcarePageQuery = graphql`
    query healthcarePageSEODataQuery {
        allStrapiSeoDescriptions {
            nodes {
                Title
                MetaTitle
                PageUrl
            }
        }
    }
`;
